import React, {useState, useEffect} from 'react';

import heroBg from '../../../assets/img/hero/hero-bg-img.png';
import heroNotificationOne from '../../../assets/img/hero/hero-notification-1.svg';
import heroNotificationTwo from '../../../assets/img/hero/hero-notification-2.svg';
import heroScrollIcon from '../../../assets/img/hero/hero-scroll-icon.svg';
import btnArrowIcon from '../../../assets/img/btn-arrow-icon.svg';

const Hero = () => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => setIsActive(true), [])

    return (
        <section id="hero" className={`hero-section ${isActive ? 'active' : ''}`}>
            <div className="bg-wrap">
                <img src={heroBg} alt="Bg"/>
            </div>
            <div className="content-wrap">
                <div className="text-wrap">
                    <h1 className="active-element">Join the Smart Traders with Apescreener</h1>
                    <h6 className="active-element">Apescreener is an AI Portfolio Advisor and tracker, which helps you
                        build, grow, and capitalize your
                        portfolio.</h6>
                </div>
                <div className="btn-wrap active-element">
                    <a href="https://apescreener.app" target="_blank" rel="noreferrer" className="btn-main">
                        <span className="text">Trade Smarter Now</span>
                        <span className="icon">
                        <img src={btnArrowIcon} alt="Icon"/>
                    </span>
                    </a>
                </div>
            </div>
            <div className="notification-wrap notification-1 active-element">
                <div className="img-wrap">
                    <img src={heroNotificationOne} alt="Notification"/>
                </div>
            </div>
            <div className="notification-wrap notification-2 active-element">
                <div className="img-wrap">
                    <img src={heroNotificationTwo} alt="Notification"/>
                </div>
            </div>
            <div className="scroll-wrap active-element">
                <img src={heroScrollIcon} alt="Scroll"/>
            </div>
        </section>
    );
}

export default Hero;
