import React from 'react';

import foundationIcon from '../../../assets/img/roadmap/roadmap-foundation-icon.svg';
import growIcon from '../../../assets/img/roadmap/roadmap-grow-icon.svg';
import evolveIcon from '../../../assets/img/roadmap/roadmap-evolve-icon.svg';
import scaleIcon from '../../../assets/img/roadmap/roadmap-scale-icon.svg';

const Roadmap = () => (
    <section id="roadmap" className="roadmap-section">
        <div className="title-wrap animation">
            <h2>Roadmap</h2>
            <h6>Simple beginning, disruptive vision.</h6>
        </div>
        <div className="items-wrap">
            <div className="item animation">
                <div className="icon-wrap">
                    <img src={foundationIcon} alt="Foundation"/>
                </div>
                <div className="headline-wrap">
                    <h5>Synapse</h5>
                </div>
                <ul>
                    <li className="done"><p>Website Launch</p></li>
                    <li className="done"><p>Bot MVP</p></li>
                    <li className="done"><p>Onboard Marketers</p></li>
                    <li className="done"><p>Algorithmic Recommendations</p></li>
                    <li className="done"><p>Ideas Platform</p></li>
                    <li className="done"><p>Onboard KOLs</p></li>
                    <li className="done"><p>Explanatory Video</p></li>
                    <li className="done"><p>Launch</p></li>
                    <li className="done"><p>Lock Liquidity</p></li>
                    <li className="done"><p>Renounce Contract</p></li>
                </ul>
            </div>
            <div className="item animation">
                <div className="icon-wrap">
                    <img src={growIcon} alt="Grow"/>
                </div>
                <div className="headline-wrap">
                    <h5>Knowledge</h5>
                </div>
                <ul>
                    <li className="done"><p>Multi-chain Support</p></li>
                    <li className="done"><p>Rebalance Strategy</p></li>
                    <li className="done"><p>AMA Tour</p></li>
                    <li className="done"><p>Partnerships</p></li>
                    <li className="done"><p>Backtesting</p></li>
                    <li className="done"><p>CG Listing</p></li>
                    <li className="done"><p>Educational Material</p></li>
                    <li className="done"><p>Competitions</p></li>
                    <li className="done"><p>dApp</p></li>
                    <li className="done"><p>Derisk Strategy</p></li>
                    <li className="done"><p>Tiered Features</p></li>
                </ul>
            </div>
            <div className="item animation">
                <div className="icon-wrap">
                    <img src={evolveIcon} alt="Evolve"/>
                </div>
                <div className="headline-wrap">
                    <h5>Wisdom</h5>
                </div>
                <ul>
                    <li className="done"><p>Holders Chart</p></li>
                    <li className="done"><p>Swap Module</p></li>
                    <li className="done"><p>Custom Charts</p></li>
                    <li className="done"><p>Narrative Exposure</p></li>
                    <li><p>Discover Module</p></li>
                    <li><p>Telegram Group Bot</p></li>
                    <li><p>Notes</p></li>
                    <li><p>Genius Community</p></li>
                    <li><p>Targeted Ads</p></li>
                    <li><p>Research Module</p></li>
                    <li><p>Mobile App</p></li>
                </ul>
            </div>
            <div className="item animation">
                <div className="icon-wrap">
                    <img src={scaleIcon} alt="Scale"/>
                </div>
                <div className="headline-wrap">
                    <h5>Enlightenment</h5>
                </div>
                <ul>
                    <li><p>Staking</p></li>
                    <li><p>Revenue Share</p></li>
                    <li><p>Gamification Module</p></li>
                    <li><p>Recommendations</p></li>
                    <li><p>DCA Strategies</p></li>
                    <li><p>AI Advisor</p></li>
                    <li><p>Advanced Asset Indicators</p></li>
                    <li><p>CEX Integration</p></li>
                    <li><p>Algorithmic Trading</p></li>
                    <li><p>Account Abstraction</p></li>
                </ul>
            </div>
        </div>
    </section>
);

export default Roadmap;
